import { useEffect, useState } from 'react'

function useDebugMessage(messages: Record<string, string>) {
  const [transformedMessages, setTransformedMessages] =
    useState<Record<string, string>>(messages)
  const [showDevMessages, setShowDevMessages] = useState(false)

  useEffect(() => {
    const eventListener = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.shiftKey && e.key === 'D') {
        setShowDevMessages((prev) => !prev)
      }
    }

    window.addEventListener('keyup', eventListener)

    return () => {
      window.removeEventListener('keyup', eventListener)
    }
  }, [messages, showDevMessages])

  useEffect(() => {
    if (showDevMessages) {
      const newMessages = Object.keys(messages).reduce(
        (acc, key) => ({
          ...acc,
          [key]: key,
        }),
        {}
      )

      setTransformedMessages(newMessages)
    } else {
      setTransformedMessages(messages)
    }
  }, [messages, showDevMessages])

  return { transformedMessages }
}

export default useDebugMessage
