import { Components, Theme } from '@mui/material'
import { blueGrey, green, grey, orange, red } from '@mui/material/colors'

const MuiCardContent: Components<Theme>['MuiCardContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.-sameLastChild:last-child': {
        paddingBottom: theme.spacing(4),
      },

      '&.status': {
        '&.-blueGrey:before': {
          backgroundColor: blueGrey['500'],
        },
        '&.-green:before': {
          backgroundColor: green.A700,
        },

        '&.-inactive:before': {
          opacity: 0.3,
        },

        '&.-orange:before': {
          backgroundColor: orange['500'],
        },

        '&.-red:before': {
          backgroundColor: red['500'],
        },

        '&:before': {
          backgroundColor: grey['300'],
          borderBottomRightRadius: 4,
          borderTopRightRadius: 4,
          bottom: 8,
          content: '""',
          display: 'block',
          left: 0,
          position: 'absolute',
          top: 8,
          width: 4,
        },

        position: 'relative',
      },

      padding: theme.spacing(6, 4, 4),
    }),
  },
}

export default MuiCardContent
